<template>
  <div class="color-picker">
    <button
      aria-label="color picker"
      @click="showPicker = !showPicker">
      <v-icon
        :class="['icon', `${colorName}-color`]">
        {{ icon }}
      </v-icon>
    </button>
    <transition name="slide-y-transition">
      <v-card
        v-if="showPicker"
        v-click-outside="{
          handler: closePicker,
          include: getClickOutsideIncluded,
        }"
        class="color-picker__card"
        max-width="250">
        <v-card-text
          data-cy="picker-card"
          class="px-2 py-0">
          <v-color-picker
            v-model="selectedColor"
            data-cy="picker"
            hide-canvas
            hide-inputs
            hide-mode-switch
            hide-sliders
            show-swatches
            :swatches="swatches"
            @input="onColorSelect"/>
        </v-card-text>
      </v-card>
    </transition>
  </div>
</template>

<script>
import {
  ENV_PRESETS,
  DEFAULT_COLOR,
  DEFAULT_ICON,
  DEFAULT_COLOR_NAME,
} from '@/utils/config/icons'

export default {
  name: 'CyInputsColorPicker',
  props: {
    colorName: {
      type: String,
      default: DEFAULT_COLOR_NAME,
    },
    icon: {
      type: String,
      default: DEFAULT_ICON,
    },
  },
  data: () => ({
    showPicker: false,
    selectedColor: DEFAULT_COLOR,
  }),
  computed: {
    swatches () {
      return _.uniqBy(ENV_PRESETS, 'background').map((color) => [color.background])
    },
    colorItem () {
      return _.find(ENV_PRESETS, { name: this.colorName })
    },
  },
  created () {
    if (!this.colorItem) {
      this.$emit('update:colorName', DEFAULT_COLOR_NAME)
    } else {
      this.selectedColor = this.colorItem.background
    }
  },
  methods: {
    closePicker () {
      this.showPicker = false
    },
    onColorSelect () {
      const newColor = _.find(ENV_PRESETS, { background: this.selectedColor })
      this.$emit('update:colorName', newColor.name || DEFAULT_COLOR_NAME)
    },
    getClickOutsideIncluded () {
      return [document.querySelector('.icon')]
    },
  },
}
</script>

<style lang="scss" scoped>
$border-radius: 8px;

.color-picker {
  &__card {
    position: absolute;
    margin-top: $spacer-2;
    padding-top: $spacer-2;
  }

  .icon-picker__icon {
    padding: $spacer;

    > i {
      width: 24px;
    }
  }
}

::v-deep v-icon {
  color: map.get($slate-grey, "light-1") !important;
}

::v-deep .v-color-picker {
  &__swatches {
    overflow-y: visible;

    > div {
      flex-wrap: nowrap;
      padding: 0;
    }
  }

  &__swatch {
    margin-bottom: 6px;
  }

  &__color {
    width: 32px;
    height: 32px;
    max-height: 32px;
    border-radius: $border-radius;
  }
}

::v-deep .v-card {
  z-index: 10;
}
</style>
